<template>
  <div class="mod-home">
    <h2>数据概览</h2>

    <!--头部-->
    <base-info ref="baseInfo" />
  </div>
</template>

<script>
import baseInfo from "./components/baseInfo";
import PanelGroupT from "./components/PanelGroupT";
import PieChart from "./components/BarChartT";
import MChart from "./components/BarChartM";
import BarChart from "./components/BarChart";
export default {
  components: {
    baseInfo,
    PanelGroupT,
    PieChart,
    BarChart,
    MChart
  },
  data() {
    return {

    };
  },

};
</script>

<style>
.mod-home {
  line-height: 1.5;
}

::v-deep .el-dialog__header {
  padding: 1vh 1vw 0 1vw;
}

::v-deep .el-dialog__body {
  padding: 20px 20px;
}
</style>

